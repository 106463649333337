import React from 'react'
import Wrapper from './wrapper'
import Button from './button'
import BookingButton from './booking-button'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import Checklist from './checklist'
import { margin } from '../distances'
import { minWidth } from '../breakpoints'
import PhotoSwipeComponent from './photoswipe'
import PhotoSwipe from 'photoswipe'
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import font from '../fonts'

const breakpoint = '@media(min-width: 860px)'
const breakpointLarge = '@media(min-width: 1150px)'

const ChecklistTitle = ({ children }) => (
  <strong
    css={{
      color: 'black',
      display: 'block',
      marginBottom: 10,
    }}
  >
    {children}
  </strong>
)

const Period = ({ title, minimum, price }) => (
  <div
    css={{
      marginBottom: 20,
      ':last-child': {
        marginBottom: 0,
      },
      [minWidth.small]: {
        alignItems: 'flex-start',
        display: 'flex',
      },
      [breakpoint]: {
        display: 'block',
      },
      [breakpointLarge]: {
        display: 'flex',
      },
    }}
  >
    <div
      css={{
        marginBottom: 10,
        [minWidth.small]: {
          marginBottom: 0,
          marginRight: 20,
        },
        [breakpoint]: {
          marginBottom: 10,
          marginRight: 0,
        },
        [breakpointLarge]: {
          marginBottom: 0,
          marginRight: 20,
        },
      }}
    >
      <strong
        css={{
          color: 'black',
          display: 'block',
        }}
      >
        {title}
      </strong>
      <span>Minimale huurperiode: {minimum}</span>
    </div>
    <div
      css={{
        boxShadow: '0 2px 8px rgba(0, 0, 0, .1)',
        color: 'black',
        fontFamily: font('Knewave'),
        padding: '10px 20px',
        textAlign: 'center',
        width: 180,
        [minWidth.small]: {
          marginLeft: 'auto',
        },
        [breakpoint]: {
          marginLeft: 0,
        },
        [breakpointLarge]: {
          marginLeft: 'auto',
        },
      }}
    >
      <span
        css={{
          fontSize: '1.33em',
        }}
      >
        &euro; {price}
      </span>{' '}
      /week
    </div>
  </div>
)

export default class Information extends React.Component {
  state = {
    facilitiesOpen: false,
    packingListOpen: false,
  }

  openGallery(items, index = 0) {
    const gallery = new PhotoSwipe(
      document.querySelector('.pswp'),
      PhotoSwipeUI_Default,
      items.edges.map((item) => {
        return {
          h: 1333,
          src: item.node.image.fixed.src,
          w: 2000,
        }
      }),
      {
        closeOnScroll: false,
        history: false,
        index,
      }
    )
    gallery.init()
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            information: markdownRemark(fileAbsolutePath: { glob: "**/information.md" }) {
              settings: frontmatter {
                title
                description
                facilitiesTitle
                facilities
                packingListTitle
                packingListDescription
                packingLists {
                  title
                  packingList
                }
              }
            }
            mainImage: file(relativePath: { eq: "gallery/first.jpg" }) {
              image: childImageSharp {
                fluid(maxWidth: 600, maxHeight: 400, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            gallery: allFile(
              filter: { relativePath: { glob: "gallery/gallery-*.jpg" } }
              sort: { fields: name }
            ) {
              edges {
                node {
                  id
                  image: childImageSharp {
                    fluid(maxWidth: 145, maxHeight: 97, quality: 80) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            fullSizes: allFile(
              filter: { relativePath: { glob: "gallery/*.jpg" } }
              sort: { fields: name }
            ) {
              edges {
                node {
                  image: childImageSharp {
                    fixed(width: 2000, height: 1333, quality: 80) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        `}
        render={({ information: { settings }, mainImage, gallery, fullSizes }) => (
          <Wrapper
            styles={[
              {
                [breakpoint]: {
                  display: 'flex',
                },
              },
              margin('bottom'),
            ]}
          >
            <div
              css={{
                marginBottom: 40,
                [breakpoint]: {
                  flexBasis: 540,
                  margin: '0 30px 0 0',
                  minWidth: 460,
                },
                [minWidth.laptop]: {
                  marginRight: 60,
                },
              }}
              id="information"
            >
              <h2 css={{ marginBottom: 18 }}>{settings.title}</h2>
              {settings.description.split("\n\n").map((text, index) => <p key={index}>{text}</p>)}

              <div
                css={{
                  marginBottom: 30,
                  [minWidth.tablet]: {
                    marginBottom: 40,
                  },
                }}
              >
                <h3>{settings.facilitiesTitle}</h3>
                <div
                  css={{
                    marginBottom: 20,
                    maxHeight: this.state.facilitiesOpen ? 'none' : 94,
                    overflow: 'hidden',
                    [minWidth.tablet]: {
                      maxHeight: this.state.facilitiesOpen ? 'none' : 108,
                    },
                  }}
                >
                  <Checklist
                    items={settings.facilities.split("\n")}
                    styles={{ margin: 0 }}
                  />
                </div>

                {!this.state.facilitiesOpen && (
                  <Button
                    small
                    color="black"
                    onClick={() => this.setState({ facilitiesOpen: true })}
                  >
                    + alle voorzieningen
                  </Button>
                )}
              </div>

              <h3>{settings.packingListTitle}</h3>
              {settings.packingListDescription.split("\n\n").map((text, index) => <p key={index}>{text}</p>)}

              <div
                css={{
                  marginBottom: this.state.packingListOpen ? 0 : 20,
                  maxHeight: this.state.packingListOpen ? 'none' : 420,
                  overflow: 'hidden',
                  position: 'relative',
                  [minWidth.tablet]: {
                    maxHeight: this.state.packingListOpen ? 'none' : 330,
                  },
                }}
              >
                {settings.packingLists.map(({ title, packingList }, index) => (
                  <div key={index}>
                    <ChecklistTitle>{title}</ChecklistTitle>
                    <Checklist
                      boxed
                      items={packingList.split("\n")}
                      styles={(index + 1 == settings.packingLists.length) ? { margin: 0 } : {}}
                    />
                  </div>
                ))}
              </div>

              {!this.state.packingListOpen && (
                <Button
                  small
                  color="black"
                  onClick={() => this.setState({ packingListOpen: true })}
                >
                  + volledige paklijst
                </Button>
              )}
            </div>

            <div
              css={{
                [breakpoint]: {
                  flexBasis: 600,
                },
              }}
            >
              <div
                css={{
                  marginBottom: 30,
                  [minWidth.laptop]: {
                    marginBottom: 60,
                  },
                }}
                id="gallery"
              >
                <PhotoSwipeComponent />

                <div
                  css={{
                    cursor: 'zoom-in',
                    marginBottom: 7,
                    overflow: 'hidden',
                  }}
                  onClick={() => this.openGallery(fullSizes)}
                >
                  <div
                    css={{
                      transition: 'transform .2s ease-out',
                      ':hover': {
                        transform: 'scale(1.035)',
                      },
                    }}
                  >
                    <Img fluid={mainImage.image.fluid} />
                  </div>
                </div>

                <div
                  css={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    margin: '0 -7px -7px 0',
                  }}
                >
                  {gallery.edges.map(({ node }, index) => (
                    <div
                      css={{
                        cursor: 'zoom-in',
                        margin: '0 7px 7px 0',
                        overflow: 'hidden',
                        width: `calc(${100 / 3}% - 7px)`,
                        [minWidth.medium]: {
                          width: `calc(${100 / 4}% - 7px)`,
                        },
                      }}
                      onClick={() => this.openGallery(fullSizes, index + 1)}
                      key={node.id}
                    >
                      <div
                        css={{
                          transition: 'transform .2s ease-out',
                          willChange: 'transform',
                          ':hover': {
                            transform: 'scale(1.05)',
                          },
                        }}
                      >
                        <Img fluid={node.image.fluid} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div>
                <h3>Huurprijzen</h3>
                <div
                  css={{
                    marginBottom: 30,
                  }}
                >
                  <Period
                    title="Voorjaar (1 januari t/m 13 juni)"
                    minimum="1 week"
                    price="790"
                  />
                  <Period
                    title="Hoogseizoen (14 juni t/m 6 september)"
                    minimum="2 weken"
                    price="890"
                  />
                  <Period
                    title="Najaar (7 september tot 21 december)"
                    minimum="1 week"
                    price="790"
                  />
                </div>

                <div
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    '@media(min-width: 500px)': {
                      alignItems: 'center',
                      flexDirection: 'row',
                    },
                    [breakpoint]: {
                      alignItems: 'flex-start',
                      flexDirection: 'column',
                    },
                    [breakpointLarge]: {
                      alignItems: 'center',
                      flexDirection: 'row',
                    },
                  }}
                >
                  <Link
                    css={{
                      color: 'inherit',
                      marginBottom: 20,
                      transition: 'color .2s ease-out',
                      '@media(min-width: 500px)': {
                        marginBottom: 0,
                        marginRight: 'auto',
                      },
                      [breakpoint]: {
                        marginBottom: 20,
                        marginRight: 0,
                      },
                      [breakpointLarge]: {
                        marginBottom: 0,
                        marginRight: 'auto',
                      },
                      ':hover': {
                        color: '#333',
                      },
                    }}
                    to="/huurvoorwaarden"
                  >
                    Lees de huurvoorwaarden
                  </Link>
                  <BookingButton>Beschikbaarheid & boeken</BookingButton>
                </div>
              </div>
            </div>
          </Wrapper>
        )}
      />
    )
  }
}
